import * as React from "react";
import { Redirect } from "react-router-dom";

const CheckAuth = ({ children }) => {
  //   const { authed } = useAuth();
  // let authed = localStorage.getItem("apartment_admin_token");
  let authed = JSON.parse(localStorage.getItem("sb-hcyugiebpxpytxbkhspl-auth-token"))

  return authed ? children : <Redirect to="/login" />;
};

export default CheckAuth;
