import {
  SET_FEATURE_NAME,
  SET_FEATURE_DESC,
  SET_FEATURE_ICON,
  SET_FEATURE_TRANS_TO_OBJ,
  CLEAR_FEATURE_PAGE,
} from "../constants/actionTypes";

const InitialStates = {
  name: "",
  desc: "",
  icon: "",
  translations: {},
};

const Feature = (state = InitialStates, action) => {
  switch (action.type) {
    case SET_FEATURE_NAME:
      return {
        ...state,
        name: action.name,
      };
    case SET_FEATURE_DESC:
      return {
        ...state,
        desc: action.desc,
      };
    case SET_FEATURE_ICON:
      return {
        ...state,
        icon: action.icon,
      };
    case SET_FEATURE_TRANS_TO_OBJ:
      return {
        ...state,
        translations: {
          ...state.translations,
          [action.code]: {
            code: action.code,
            title: action.title,
            description: action.value,
          },
        },
      };
    case CLEAR_FEATURE_PAGE:
      return InitialStates;
    default:
      return state;
  }
};

export default Feature;
