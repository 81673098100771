// loading page actions
export const SET_PAGE_LOADING_STATUS = "SET_PAGE_LOADING_STATUS";

export const SET_LOGIN_EMAIL = "SET_LOGIN_EMAIL";
export const SET_LOGIN_PASSWORD = "SET_LOGIN_PASSWORD";

// rules actions
export const SET_RULE_NAME = "SET_RULE_NAME";
export const SET_TRANS_TO_OBJ = "SET_TRANS_TO_OBJ";
export const CLEAR_RULE_PAGE = "CLEAR_RULE_PAGE";

// rules features actions
export const SET_FEATURE_NAME = "SET_FEATURE_NAME";
export const SET_FEATURE_DESC = "SET_FEATURE_DESC";
export const SET_FEATURE_ICON = "SET_FEATURE_ICON";
export const SET_FEATURE_TRANS_TO_OBJ = "SET_FEATURE_TRANS_TO_OBJ";
export const CLEAR_FEATURE_PAGE = "CLEAR_FEATURE_PAGE";

// lang actions
export const SET_LANG_NAME = "SET_LANG_NAME";
export const SET_LANG_CODE = "SET_LANG_CODE";
export const CLEAR_LANG_PAGE = "CLEAR_LANG_PAGE";

// add partments
export const SET_APARTMENT_NAME = "SET_APARTMENT_NAME";
export const SET_APARTMENT_DESC = "SET_APARTMENT_DESC";
export const SET_APARTMENT_TRANS = "SET_APARTMENT_TRANS";
export const SET_APARTMENT_LOACTION = "SET_APARTMENT_LOACTION";
export const SET_APARTMENT_NO_OF_GUESTS = "SET_APARTMENT_NO_OF_GUESTS";
export const SET_APARTMENT_NO_OF_BEDS = "SET_APARTMENT_NO_OF_BEDS";
export const SET_APARTMENT_NO_OF_BATH_ROOMS = "SET_APARTMENT_NO_OF_BATH_ROOMS";
export const SET_APARTMENT_NOTE = "SET_APARTMENT_NOTE";
export const SET_APARTMENT_IMAGES = "SET_APARTMENT_IMAGES";
export const SET_APARTMENT_COVER_IMAGE = "SET_APARTMENT_COVER_IMAGE";
export const CLEAR_PAGE = "CLEAR_PAGE";

// price actions
export const SET_PRICE = "SET_PRICE";
export const SET_PRICE_APARTMENT = "SET_PRICE_APARTMENT";
export const SET_PRICE_START_DATE = "SET_PRICE_START_DATE";
export const SET_PRICE_END_DATE = "SET_PRICE_END_DATE";
export const SET_PRICE_STATUS = "SET_PRICE_STATUS";

// guests actions
export const SET_FIRST_NAME = "SET_FIRST_NAME";
export const SET_LAST_NAME = "SET_LAST_NAME";
export const SET_CITY = "SET_CITY";
export const SET_ZIP_CODE = "SET_ZIP_CODE";
export const SET_ADDRESS = "SET_ADDRESS";
export const SET_CONTACT = "SET_CONTACT";
export const SET_COUNTRY = "SET_COUNTRY";

// guests actions
export const SET_GUEST = "SET_GUEST";
export const SET_APARTMENT = "SET_APARTMENT";
export const SET_CHECK_IN_DATE = "SET_CHECK_IN_DATE";
export const SET_CHECK_OUT_DATE = "SET_CHECK_OUT_DATE";
export const SET_CANCELED = "SET_CANCELED";
export const SET_CONFIRMED = "SET_CONFIRMED";
export const SET_CAL_ID = "SET_CAL_ID";
export const SET_ADULTS = "SET_ADULTS";
export const SET_CHILDS = "SET_CHILDS";
