import {
  SET_APARTMENT_NAME,
  SET_APARTMENT_DESC,
  SET_APARTMENT_TRANS,
  SET_APARTMENT_LOACTION,
  SET_APARTMENT_NO_OF_BATH_ROOMS,
  SET_APARTMENT_NO_OF_BEDS,
  SET_APARTMENT_NO_OF_GUESTS,
  SET_APARTMENT_IMAGES,
  SET_APARTMENT_COVER_IMAGE,
  SET_APARTMENT_NOTE,
  CLEAR_PAGE,
} from "../constants/actionTypes";

const InitialStates = {
  name: "",
  desc: "",
  location: "",
  guests: 0,
  beds: 0,
  bathRooms: 0,
  note: "",
  images: {},
  coverImage: null,
  translations: {},
};

const AddApartments = (state = InitialStates, action) => {
  switch (action.type) {
    case SET_APARTMENT_NAME:
      return {
        ...state,
        name: action.name,
      };
    case SET_APARTMENT_DESC:
      return {
        ...state,
        desc: action.desc,
      };
    case SET_APARTMENT_LOACTION:
      return {
        ...state,
        location: action.location,
      };
    case SET_APARTMENT_NO_OF_BATH_ROOMS:
      return {
        ...state,
        bathRooms: action.bathRooms,
      };
    case SET_APARTMENT_NO_OF_BEDS:
      return {
        ...state,
        beds: action.beds,
      };
    case SET_APARTMENT_NO_OF_GUESTS:
      return {
        ...state,
        guests: action.guests,
      };
    case SET_APARTMENT_NOTE:
      return {
        ...state,
        note: action.note,
      };
    case SET_APARTMENT_IMAGES:
      return {
        ...state,
        images: action.images,
      };
    case SET_APARTMENT_COVER_IMAGE:
      return {
        ...state,
        coverImage: action.coverImage,
      };
    case SET_APARTMENT_TRANS:
      return {
        ...state,
        translations: {
          ...state.translations,
          [action.code]: {
            code: action.code,
            title: action.title,
            description: action.value,
          },
        },
      };
    case CLEAR_PAGE:
      return InitialStates;
    default:
      return state;
  }
};

export default AddApartments;
