import {
  SET_PRICE_APARTMENT,
  SET_PRICE_END_DATE,
  SET_PRICE_START_DATE,
  SET_PRICE_STATUS,
  SET_PRICE,
} from "../constants/actionTypes";

const InitialStates = {
  apart_id: "",
  price: 0,
  start_date: "",
  end_date: "",
  status: false,
};

const Lang = (state = InitialStates, action) => {
  switch (action.type) {
    case SET_PRICE_APARTMENT:
      return {
        ...state,
        apart_id: action.apart_id,
      };
    case SET_PRICE:
      return {
        ...state,
        price: action.price,
      };
    case SET_PRICE_START_DATE:
      return {
        ...state,
        start_date: action.start_date,
      };
    case SET_PRICE_END_DATE:
      return {
        ...state,
        end_date: action.end_date,
      };
    case SET_PRICE_STATUS:
      return {
        ...state,
        status: action.status,
      };
    default:
      return state;
  }
};

export default Lang;
