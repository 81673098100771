import {
  SET_FIRST_NAME,
  SET_LAST_NAME,
  SET_CITY,
  SET_COUNTRY,
  SET_ZIP_CODE,
  SET_ADDRESS,
  SET_CONTACT,
} from "../constants/actionTypes";

const InitialStates = {
  first_name: "",
  last_name: "",
  city: "",
  zip_code: "",
  contact: "",
  country: "",
  address: "",
};

const Lang = (state = InitialStates, action) => {
  switch (action.type) {
    case SET_FIRST_NAME:
      return {
        ...state,
        first_name: action.first_name,
      };
    case SET_LAST_NAME:
      return {
        ...state,
        last_name: action.last_name,
      };
    case SET_CITY:
      return {
        ...state,
        city: action.city,
      };
    case SET_COUNTRY:
      return {
        ...state,
        country: action.country,
      };
    case SET_ZIP_CODE:
      return {
        ...state,
        zip_code: action.zip_code,
      };
    case SET_CONTACT:
      return {
        ...state,
        contact: action.contact,
      };
    case SET_ADDRESS:
      return {
        ...state,
        address: action.address,
      };
    default:
      return state;
  }
};

export default Lang;
