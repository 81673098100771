import { SET_LANG_NAME, SET_LANG_CODE,CLEAR_LANG_PAGE } from "../constants/actionTypes";

const InitialStates = {
  name: "",
  code: "",
};

const Lang = (state = InitialStates, action) => {
  switch (action.type) {
    case SET_LANG_NAME:
      return {
        ...state,
        name: action.name,
      };
    case SET_LANG_CODE:
      return {
        ...state,
        code: action.code,
      };
    case CLEAR_LANG_PAGE:
      return InitialStates;
    default:
      return state;
  }
};

export default Lang;
