import {
  SET_GUEST,
  SET_APARTMENT,
  SET_CHECK_IN_DATE,
  SET_CHECK_OUT_DATE,
  SET_CANCELED,
  SET_CONFIRMED,
  SET_CAL_ID,
  SET_ADULTS,
  SET_CHILDS,
} from "../constants/actionTypes";

const InitialStates = {
  apart: "",
  guest: "",
  check_in: "",
  check_out: "",
  cancel: "",
  confirm: "",
  cal_id: "",
  adults: 0,
  childs: 0,
};

const Booking = (state = InitialStates, action) => {
  switch (action.type) {
    case SET_GUEST:
      return {
        ...state,
        guest: action.guest,
      };
    case SET_APARTMENT:
      return {
        ...state,
        apart: action.apart,
      };
    case SET_CHECK_IN_DATE:
      return {
        ...state,
        check_in: action.check_in,
      };
    case SET_CHECK_OUT_DATE:
      return {
        ...state,
        check_out: action.check_out,
      };
    case SET_CANCELED:
      return {
        ...state,
        cancel: action.cancel,
      };
    case SET_CONFIRMED:
      return {
        ...state,
        confirm: action.confirm,
      };
    case SET_ADULTS:
      return {
        ...state,
        adults: action.adults,
      };
    case SET_CHILDS:
      return {
        ...state,
        childs: action.childs,
      };
    default:
      return state;
  }
};

export default Booking;
