import {
  SET_RULE_NAME,
  SET_TRANS_TO_OBJ,
  CLEAR_RULE_PAGE,
} from "../constants/actionTypes";

const InitialStates = {
  rule_name: "",
  translations: {},
};

const Rule = (state = InitialStates, action) => {
  switch (action.type) {
    case SET_RULE_NAME:
      return {
        ...state,
        rule_name: action.rule_name,
      };
    case SET_TRANS_TO_OBJ:
      return {
        ...state,
        translations: {
          ...state.translations,
          [action.code]: {
            code: action.code,
            description: action.value,
          },
        },
      };
    case CLEAR_RULE_PAGE:
      return InitialStates;
    default:
      return state;
  }
};

export default Rule;
