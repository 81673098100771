import React, { lazy } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  // Redirect,
} from "react-router-dom";
import AccessibleNavigationAnnouncer from "./components/AccessibleNavigationAnnouncer";
import CheckAuth from "./axis-admin/app/CheckAuth";

// pages
const Login = lazy(() => import("./pages/Login"));
const Dashboard = lazy(() => import("./axis-admin/pages/dashboard/Dashboard"));
const Rules = lazy(() => import("./axis-admin/pages/ManageInfo/rules/Index"));
const Features = lazy(() =>
  import("./axis-admin/pages/ManageInfo/features/Index")
);
const AddtionInfo = lazy(() =>
  import("./axis-admin/pages/ManageInfo/addtionInfo/Index")
);
const Apartments = lazy(() => import("./axis-admin/pages/apartments/Index"));
const ApartmentEdit = lazy(() =>
  import("./axis-admin/pages/apartments/EditIndex")
);
const ViewIndex = lazy(() => import("./axis-admin/pages/apartments/ViewIndex"));
const Lang = lazy(() => import("./axis-admin/pages/Lang/Index"));
const AddPrice = lazy(() => import("./axis-admin/pages/AddPrice/Index"));
const Guests = lazy(() => import("./axis-admin/pages/Guests/Index"));
const Booking = lazy(() => import("./axis-admin/pages/Booking/Index"));
const AddRules = lazy(() => import("./axis-admin/pages/AddRules/Index"));
const AddFeatures = lazy(() => import("./axis-admin/pages/AddFeatures/Index"));
const Settings = lazy(() => import("./axis-admin/pages/settings/Index"));
const Privacy = lazy(() => import("./axis-admin/pages/settings/Privacy/Index"));
const Terms = lazy(() => import("./axis-admin/pages/settings/Terms/Index"));
const Profile = lazy(() => import("./axis-admin/pages/Profile/Index"));
const BookingDetails = lazy(() => import("./axis-admin/pages/Booking/BookingDetails/Index"));
const SocialMedia = lazy(() => import("./axis-admin/pages/settings/SocialMedia/Index"));
// const TestPage = lazy(() => import("./axis-admin/pages/TestPage"));

function PageRouter() {
  localStorage.setItem("theme", "light");
  return (
    <>
      <Router>
        <AccessibleNavigationAnnouncer />
        <Switch>
          <Route path="/login" component={Login} />
          <CheckAuth>
            <Route exact path="/" component={Dashboard} />
            <Route path="/rules" component={Rules} />
            <Route path="/features" component={Features} />
            <Route path="/addtion/info" component={AddtionInfo} />
            <Route path="/apartment/add" component={Apartments} />
            <Route path="/apartment/edit/:id" component={ApartmentEdit} />
            <Route path="/apartment/view" component={ViewIndex} />
            <Route path="/apartment/lang" component={Lang} />
            <Route path="/apartment/price" component={AddPrice} />
            <Route path="/apartment/guests" component={Guests} />
            <Route path="/add/booking" component={Booking} />
            <Route path="/add/rules" component={AddRules} />
            <Route path="/add/features" component={AddFeatures} />
            <Route path="/settings" component={Settings} />
            <Route path="/privacy" component={Privacy} />
            <Route path="/terms" component={Terms} />
            <Route path="/profile" component={Profile} />
            <Route path="/booking/details/:id" component={BookingDetails} />
            <Route path="/social/links" component={SocialMedia} />
            {/* <Route path="/test" component={TestPage} /> */}
          </CheckAuth>
          {/* <Redirect exact from="/" to="/login" /> */}
        </Switch>
      </Router>
    </>
  );
}

export default PageRouter;
