import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import "../../assets/css/custom.css";

import LoadingPage from "../reducers/LoadingPage";
import LoginPage from "../reducers/LoginPage";
import Rule from "../reducers/Rule";
import Feature from "../reducers/Feature";
import AddApartments from "../reducers/AddApartments";
import Lang from "../reducers/Lang";
import Price from "../reducers/Price";
import Guests from "../reducers/Guests";
import Booking from "../reducers/Booking";

const allReducers = combineReducers({
  LoadingPage: LoadingPage,
  LoginPage: LoginPage,
  Rule: Rule,
  Feature: Feature,
  AddApartments: AddApartments,
  Lang: Lang,
  Price: Price,
  Guests: Guests,
  Booking: Booking,
});

const stores = applyMiddleware(thunk)(createStore);
// const stores = configureStore({
//     reducer:{
//         itemCart: itemCart
//     }
// })
// export default stores;
export default stores(allReducers);
