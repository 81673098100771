const InitialStates = {
  email: "",
  password: "",
};

const ItemView = (state = InitialStates, action) => {
  switch (action.type) {
    case "SET_LOGIN_EMAIL":
      return {
        ...state,
        email: action.email,
      };
    case "SET_LOGIN_PASSWORD":
      return {
        ...state,
        password: action.password,
      };
    default:
      return state;
  }
};

export default ItemView;
