import { SET_PAGE_LOADING_STATUS } from "../constants/actionTypes";

const InitialStates = {
  loading: false,
};

const LoadingPage = (state = InitialStates, action) => {
  switch (action.type) {
    case SET_PAGE_LOADING_STATUS:
      return {
        ...state,
        loading: action.loading,
      };
    default:
      return state;
  }
};

export default LoadingPage;
